// @import "~bootstrap/scss/bootstrap";
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";


$primary: #1c1a19;
$secondary: #ff4e03;

$body-bg: #080707;
$body-color: $secondary;


@import '~bootstrap/scss/bootstrap.scss';


